import { IComponentMapping } from './types';

interface IComponentType {
  type: string;
  const: string;
  family: string;
  family_description: string;
  readme_link: string;
  component_frequency: number;
}

// Interface for the `init_parameters` property within `IProperties`
interface IInitParameters {
  type: string;
  properties: Record<string, unknown>;
  required: string[];
  description: string;
}

// Interface for properties in IValue
interface IProperties {
  type: IComponentType;
  init_parameters: IInitParameters;
}

// Interface for the `value` in `getComponentMappings`
export interface IValue {
  type: string;
  title: string;
  description?: string;
  properties: IProperties | Record<string, any>;
  oftenOutputsTo?: string[];
  oftenReceivesFrom?: string[];
  dynamic_params?: Record<string, unknown>;
  package_version?: string;
}

interface IInputOutput {
  definitions: Record<string, unknown>;
  properties: Record<string, any>;
  type: string;
  required: string[];
  variadic?: boolean;
}

// Function to get component mappings with improved typing
export const getComponentMappings = (components: Record<string, IValue>): IComponentMapping[] =>
  Object.entries(components).map(([key, value]: [string, IValue]) => {
    const {
      properties,
      description,
      oftenOutputsTo,
      oftenReceivesFrom,
      dynamic_params: dynamicParams,
      package_version: packageVersion,
    } = value;

    if (!properties || Object.keys(properties).length < 1)
      return {
        type: key,
        typeConst: '',
        initParams: {},
      };

    const { type, init_parameters: initParams } = properties as IProperties;
    const { properties: initParamsProperties, required: initParamsRequired } = initParams;
    const {
      const: typeConst,
      family,
      family_description: familyDescription,
      component_frequency: componentFrequency,
    } = type;
    const finalParams: Record<string, { required: boolean; [key: string]: unknown }> = {};

    Object.entries(initParamsProperties).forEach(([paramKey, param]: [string, any]) => {
      finalParams[paramKey] = {
        ...param,
        required: initParamsRequired.includes(paramKey),
      };
    });

    return {
      type: key,
      typeConst,
      family,
      familyDescription,
      description,
      initParams: finalParams,
      oftenOutputsTo: oftenOutputsTo,
      oftenReceivesFrom: oftenReceivesFrom,
      docsLink: properties.type.readme_link,
      dynamicParams,
      packageVersion,
      componentFrequency,
    };
  });

export const getInputOutputMappings = (IOSchema: any[]) => {
  if (!IOSchema || !Array.isArray(IOSchema)) return {};
  return IOSchema.reduce(
    (
      acc: Record<string, any>,
      { name, input, output }: { name: string; input: IInputOutput; output: IInputOutput },
    ) => {
      const {
        properties: inputProperties,
        required: inputRequired,
        definitions: inputDefinitions,
      } = input;
      const {
        properties: outputProperties,
        required: outputRequired,
        definitions: outputDefinitions,
      } = output;

      let inputs: { key: string; type: string; required: boolean; variadic?: boolean }[] = [];
      let outputs: { key: string; type: string; required: boolean; variadic?: boolean }[] = [];

      Object.entries(inputProperties).map(([key, value]) => {
        let tempInput = {
          key,
          type: '',
          items: {},
          required: (inputRequired as string[]).includes(key),
          variadic: false,
          _raw: {
            ...value,
            definitions: value.definitions
              ? { ...value.definitions, ...inputDefinitions }
              : inputDefinitions,
          },
        };
        const { type, items, variadic } = value;
        const typeIsArray = Array.isArray(type);
        const types = typeIsArray ? type.filter((v: string) => v !== 'null') : [type];

        tempInput = {
          ...tempInput,
          type: types.length === 1 ? types[0] : types,
          items,
          variadic: variadic ?? false,
        };
        inputs.push(tempInput);
      });

      if (outputProperties)
        Object.entries(outputProperties).map(([key, value]) => {
          let tempOutput = {
            key,
            type: '',
            items: {},
            required: (outputRequired as string[]).includes(key),
            variadic: false,
            _raw: {
              ...value,
              definitions: value.definitions
                ? { ...value.definitions, ...outputDefinitions }
                : outputDefinitions,
            },
          };
          const { type, items, variadic } = value;
          const typeIsArray = Array.isArray(type);
          const types = typeIsArray ? type.filter((v: string) => v !== 'null') : [type];

          tempOutput = {
            ...tempOutput,
            type: types.length === 1 ? types[0] : types,
            items,
            variadic: variadic ?? false,
          };
          outputs.push(tempOutput);
        });

      acc[name] = { name, input: inputs, output: outputs };
      return acc;
    },
    {},
  );
};
