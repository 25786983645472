import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Edge, Node, ReactFlowInstance } from '@xyflow/react';
import { AxiosResponse } from 'axios';
import yamlParser from 'js-yaml';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { handleErrorNotification } from '@utils/error';
import {
  generateIntegratedPipelineYamlApi,
  getPipelineSchemaApi,
  getPipelineSchemaInputOutputApi,
} from '@api/pipeline';
import { StatusCodes } from '@constants/enum/common';
import { PipelineMessages } from '@constants/pipelines';
import { addNotification } from '@redux/actions/notificationActions';
import { fetchPipelineYaml } from '@redux/actions/pipelineActions';
import { RootState } from '@redux/store';
import { NotificationType } from '@redux/types/types';
import { StudioYamlTabsKeys } from '../constants/pipeline-studio';
import { getYamlString } from '../logic/generateYaml';
import { getComponentMappings, getInputOutputMappings } from '../logic/transformSchema';
import { HistoryItem, IComponentMapping } from '../logic/types';

interface StudioState {
  pipelineSchema: Record<string, any> | null;
  haystackTypes: Record<string, any> | {};
  componentMap: IComponentMapping[];
  fetchPipelineSchemaStatus: StatusCodes;
  inputOutputMap: Record<string, any>;
  fetchPipelineInputOutputsStatus: StatusCodes;
  activeTab: StudioYamlTabsKeys;
  exportCodeModalOpen: boolean;
  isYamlView: boolean;
  downloadYamlModalOpen: boolean;
  yaml: string;
  indexingYaml: string;
  queryYaml: string;
  fetchedSingleIntegratedPipelineYaml: StatusCodes;
  fetchedIntegratedPipelinesYaml: StatusCodes;
  indexingNodes: Node[];
  queryNodes: Node[];
  indexingEdges: Edge[];
  queryEdges: Edge[];
  isIndexingGraphValid: boolean;
  isQueryGraphValid: boolean;
  isReadOnly: boolean;
  showReadOnlyWarningModal: boolean;
  hoveredNodeId: string;
  reactFlowInstance: ReactFlowInstance | null;
  pastIndexingReactFlowState: HistoryItem[];
  futureIndexingReactFlowState: HistoryItem[];
  pastQueryReactFlowState: HistoryItem[];
  futureQueryReactFlowState: HistoryItem[];
  newNodeAdded: boolean;
  isDataLoading: boolean;
  nodeParamModalVisible: { nodeId: string; paramName: string };
  documentStoreComponents: Record<string, any>;
  disconnectedDocumentStores: string[];
  saveStudioPipelineStatus: StatusCodes;
  isIndexingPage: boolean;
}

export const initialState: StudioState = {
  pipelineSchema: {},
  haystackTypes: {},
  componentMap: [],
  fetchPipelineSchemaStatus: StatusCodes.IDLE,
  inputOutputMap: {},
  fetchPipelineInputOutputsStatus: StatusCodes.IDLE,
  activeTab: StudioYamlTabsKeys.INDEXING_YAML,
  exportCodeModalOpen: false,
  isYamlView: false,
  downloadYamlModalOpen: false,
  yaml: '',
  indexingYaml: '',
  queryYaml: '',
  fetchedSingleIntegratedPipelineYaml: StatusCodes.IDLE,
  fetchedIntegratedPipelinesYaml: StatusCodes.IDLE,
  indexingNodes: [],
  queryNodes: [],
  indexingEdges: [],
  queryEdges: [],
  isIndexingGraphValid: true,
  isQueryGraphValid: true,
  isReadOnly: false,
  showReadOnlyWarningModal: false,
  hoveredNodeId: '',
  reactFlowInstance: null,
  pastIndexingReactFlowState: [],
  futureIndexingReactFlowState: [],
  pastQueryReactFlowState: [],
  futureQueryReactFlowState: [],
  newNodeAdded: false,
  isDataLoading: false,
  nodeParamModalVisible: { nodeId: '', paramName: '' },
  documentStoreComponents: {},
  disconnectedDocumentStores: [],
  saveStudioPipelineStatus: StatusCodes.IDLE,
  isIndexingPage: false,
};

export const fetchPipelineSchema = createAsyncThunk('users/fetchPipelineSchema', async () => {
  const response = await getPipelineSchemaApi();
  return response.data.component_schema;
});

export const fetchPipelineInputOutputs = createAsyncThunk(
  'studio/fetchPipelineInputOutputs',
  async () => {
    const pipelineSchema = await getPipelineSchemaApi();
    const response = await getPipelineSchemaInputOutputApi();

    let allInputOutput = response.data;

    // Add document store outputs and check for document_store init parameters
    const documentStores = Object.entries(
      pipelineSchema.data.component_schema.definitions.haystackTypes,
    ).filter(([key]) => key.includes('DocumentStore'));
    // Check all Components for document_store init parameter
    const components = pipelineSchema.data.component_schema.definitions.Components;
    Object.entries(components).forEach(([componentName, componentSchema]: [string, any]) => {
      const initParams = componentSchema?.properties?.init_parameters;
      const documentStoreParam = initParams?.properties?.document_store;

      // Check if the document_store is a native haystack document store
      // this is a workaround for custom components that have a document store as an init parameter.
      // Our document stores are extracted in the schema via haystack types.
      // For custom components, we don't have that => fallback to old implementation
      const isNativeHaystackDocstore =
        initParams?.properties?.document_store?.$ref?.includes('#/definitions/haystackTypes') ||
        initParams?.properties?.document_store?.anyOf?.some((item: any) =>
          item?.$ref?.includes('#/definitions/haystackTypes'),
        ); // TODO: We definitely need to make this more robust. We can't rely on the schema format to render the docstore. Let's add a key `native_haystack_docstore` to the component schema.
      if (documentStoreParam && isNativeHaystackDocstore) {
        const existingIO = allInputOutput.find((io: any) => io.name === componentName);
        if (existingIO) {
          // Add document_store to existing input schema
          try {
            existingIO.input.properties.document_store = {
              type: 'object',
              additionalProperties: false,
              properties: {
                ...(pipelineSchema.data.component_schema.definitions.haystackTypes[
                  documentStoreParam.$ref.split('/').pop()
                ]?.properties?.init_parameters?.properties || {}),
              },
            };
          } catch (e) {
            existingIO.input.properties.document_store = documentStoreParam;
            try {
              const documentStoreTypes =
                pipelineSchema.data.component_schema.definitions.haystackTypes;
              existingIO.input.properties.document_store.definitions = {
                haystackTypes: documentStoreTypes,
              };
            } catch (error) {}
          }
          if (!existingIO.input.required.includes('document_store')) {
            existingIO.input.required.push('document_store');
          }
        } else {
          // Create new input/output schema with document_store
          allInputOutput.push({
            name: componentName,
            input: {
              type: 'object',
              properties: {
                document_store: {
                  ...documentStoreParam,
                },
              },
              required: ['document_store'],
              additionalProperties: false,
              definitions: pipelineSchema.data.component_schema.definitions,
            },
            output: {
              type: 'object',
              properties: {},
              required: [],
              additionalProperties: false,
            },
          });
        }
      }
    });

    // Add document store outputs (existing logic)
    documentStores.forEach(([name]) => {
      if (!allInputOutput.find((io: any) => io.name === name)) {
        const docummentStoreParams =
          pipelineSchema.data.component_schema.definitions.haystackTypes[name]?.properties
            ?.init_parameters || {};

        allInputOutput.push({
          name,
          input: {
            type: 'object',
            properties: {},
            required: [],
            additionalProperties: false,
          },
          output: {
            type: 'object',
            properties: {
              document_store: {
                ...docummentStoreParams,
                additionalProperties: false,
              },
            },
            required: ['document_store'],
            additionalProperties: false,
            definitions: pipelineSchema.data.component_schema.definitions,
          },
        });
      }
    });

    return allInputOutput;
  },
);

export const generateIntegratedPipelineYaml = createAsyncThunk(
  'studio/generateIntegratedPipelineYaml',
  async (payload: { original: string; updated: Record<string, unknown> }) => {
    const response = await generateIntegratedPipelineYamlApi(payload);
    return response.data;
  },
);

// TODO(NEW_STUDIO): Remove when old studio is removed
export const generateIntegratedIndexingAndQueryPipelineYaml = createAsyncThunk(
  'studio/generateIntegratedIndexingAndQueryPipelineYaml',
  async (payload: {
    indexing: { original: string; updated: Record<string, unknown> };
    query: { original: string; updated: Record<string, unknown> };
    indexingPipelineCanBeEdited: boolean;
  }) => {
    const response = {
      indexingYaml: '',
      queryYaml: '',
    };
    const indexingResponse = payload.indexingPipelineCanBeEdited
      ? (await generateIntegratedPipelineYamlApi(payload.indexing)).data
      : payload.indexing.original;
    response.indexingYaml = indexingResponse;
    const queryResponse = await generateIntegratedPipelineYamlApi(payload.query);
    response.queryYaml = queryResponse.data;
    return response;
  },
);

export const saveStudioPipeline = createAsyncThunk(
  'studio/saveStudioPipeline',
  async (
    {
      flowGraphPayload,
      updatePipelineAPICall,
    }: {
      updatePipelineAPICall: (yaml: string) => Promise<AxiosResponse>;
      flowGraphPayload: { original: string; updated: Record<string, unknown> };
    },
    { rejectWithValue, getState, dispatch },
  ) => {
    const { isYamlView, yaml } = (getState() as RootState).studioStore;

    try {
      let yamlToSave = yaml;
      if (!isYamlView) {
        const { data: yamlFromGraph } = await generateIntegratedPipelineYamlApi(flowGraphPayload);
        yamlToSave = yamlFromGraph;
      }

      await updatePipelineAPICall(yamlToSave);
      dispatch(
        addNotification({
          content: PipelineMessages.SAVE_SUCCESS,
          type: NotificationType.Success,
        }),
      );
      return yamlToSave;
    } catch (error) {
      handleErrorNotification(error, dispatch);
      return rejectWithValue(error);
    }
  },
);

export const studioSlice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    resetStudioState: (state) => {
      const {
        pipelineSchema,
        haystackTypes,
        componentMap,
        inputOutputMap,
        isIndexingPage,
        isReadOnly,
      } = state;
      Object.assign(state, {
        ...initialState,
        pipelineSchema,
        haystackTypes,
        componentMap,
        inputOutputMap,
        isIndexingPage,
        isReadOnly,
      });
    },
    setActiveTab: (state, action: PayloadAction<StudioYamlTabsKeys>) => {
      state.activeTab = action.payload;
    },
    setExportCodeModalOpen: (state, action: PayloadAction<boolean>) => {
      state.exportCodeModalOpen = action.payload;
    },
    setIsYamlView: (state, action: PayloadAction<boolean>) => {
      state.isYamlView = action.payload;
    },
    setDownloadYamlModalOpen: (state, action: PayloadAction<boolean>) => {
      state.downloadYamlModalOpen = action.payload;
    },
    setIsIndexingGraphValid: (state, action: PayloadAction<boolean>) => {
      state.isIndexingGraphValid = action.payload;
    },
    setIsQueryGraphValid: (state, action: PayloadAction<boolean>) => {
      state.isQueryGraphValid = action.payload;
    },
    setYaml: (state, action: PayloadAction<string>) => {
      state.yaml = action.payload;
    },
    // TODO(NEW_STUDIO): Remove when old studio is removed
    setIndexingYaml: (state, action: PayloadAction<string>) => {
      try {
        yamlParser.load(action.payload);
        state.isIndexingGraphValid = true;
      } catch (e) {
        state.isIndexingGraphValid = false;
      }
      state.indexingYaml = action.payload;
    },
    // TODO(NEW_STUDIO): Remove when old studio is removed
    setQueryYaml: (state, action: PayloadAction<string>) => {
      try {
        yamlParser.load(action.payload);
        state.isQueryGraphValid = true;
      } catch (e) {
        state.isQueryGraphValid = false;
      }
      state.queryYaml = action.payload;
    },

    setComponentMap: (state, action: PayloadAction<IComponentMapping[]>) => {
      state.componentMap = action.payload;
    },
    setNodesInputOutput: (state, action: PayloadAction<Record<string, any>>) => {
      state.inputOutputMap = action.payload;
    },
    updateInputOutputMap: (state, action: PayloadAction<Record<string, any>>) => {
      const hasNewValues = Object.keys(action.payload).some(
        (key) => !isEqual(state.inputOutputMap[key], action.payload[key]),
      );

      if (hasNewValues) {
        state.inputOutputMap = {
          ...state.inputOutputMap,
          ...action.payload,
        };
      }
    },
    setIndexingNodes: (state, action: PayloadAction<Node[]>) => {
      // Why are we using cloneDeep here?
      // This is why: When using Redux to store nodes and edges data for React Flow,
      // issues can arise due to the need for immutability and the frequent updates required
      // for these complex structures.

      const hasChanged = !isEqual(action.payload, state.indexingNodes);
      if (hasChanged) {
        const updatedNodes = cloneDeep(action.payload);
        state.indexingNodes = updatedNodes;
      }
    },
    setQueryNodes: (state, action: PayloadAction<Node[]>) => {
      const hasChanged = !isEqual(action.payload, state.queryNodes);
      if (hasChanged) {
        const updatedNodes = cloneDeep(action.payload);
        state.queryNodes = updatedNodes;
      }
    },
    setIndexingEdges: (state, action: PayloadAction<Edge[]>) => {
      const hasChanged = !isEqual(action.payload, state.indexingEdges);
      if (hasChanged) {
        const updatedEdges = cloneDeep(action.payload);
        state.indexingEdges = updatedEdges;
      }
    },
    setQueryEdges: (state, action: PayloadAction<Edge[]>) => {
      const hasChanged = !isEqual(action.payload, state.queryEdges);
      if (hasChanged) {
        const updatedEdges = cloneDeep(action.payload);
        state.queryEdges = updatedEdges;
      }
    },
    setIsReadOnly: (state, action: PayloadAction<boolean>) => {
      state.isReadOnly = action.payload;
    },
    setShowReadOnlyWarningModal: (state, action: PayloadAction<boolean>) => {
      state.showReadOnlyWarningModal = action.payload;
    },
    setHoveredNodeId: (state, action: PayloadAction<string>) => {
      state.hoveredNodeId = action.payload;
    },
    setReactFlowInstance: (state, action: PayloadAction<ReactFlowInstance>) => {
      state.reactFlowInstance = action.payload;
    },
    setPastIndexingReactFlowState: (state, action: PayloadAction<HistoryItem[]>) => {
      const hasChanged = !isEqual(action.payload, state.pastIndexingReactFlowState);
      if (hasChanged) {
        const updatedPast = cloneDeep(action.payload);
        state.pastIndexingReactFlowState = updatedPast;
      }
    },
    setFutureIndexingReactFlowState: (state, action: PayloadAction<HistoryItem[]>) => {
      const hasChanged = !isEqual(action.payload, state.futureIndexingReactFlowState);
      if (hasChanged) {
        const updatedFuture = cloneDeep(action.payload);
        state.futureIndexingReactFlowState = updatedFuture;
      }
    },
    setPastQueryReactFlowState: (state, action: PayloadAction<HistoryItem[]>) => {
      const hasChanged = !isEqual(action.payload, state.pastQueryReactFlowState);
      if (hasChanged) {
        const updatedPast = cloneDeep(action.payload);
        state.pastQueryReactFlowState = updatedPast;
      }
    },
    setFutureQueryReactFlowState: (state, action: PayloadAction<HistoryItem[]>) => {
      const hasChanged = !isEqual(action.payload, state.futureQueryReactFlowState);
      if (hasChanged) {
        const updatedFuture = cloneDeep(action.payload);
        state.futureQueryReactFlowState = updatedFuture;
      }
    },
    setNewNodeAdded: (state, action: PayloadAction<boolean>) => {
      state.newNodeAdded = action.payload;
    },
    setIsDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isDataLoading = action.payload;
    },
    setNodeParamModalVisible: (
      state,
      action: PayloadAction<{ nodeId: string; paramName: string }>,
    ) => {
      state.nodeParamModalVisible = action.payload;
    },
    setDisconnectedDocumentStores: (state, action: PayloadAction<string[]>) => {
      state.disconnectedDocumentStores = action.payload;
    },
    setIsIndexingPage: (state, action: PayloadAction<boolean>) => {
      state.isIndexingPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPipelineSchema.pending, (state) => {
      state.fetchPipelineSchemaStatus = StatusCodes.IN_PROGRESS;
    });
    builder.addCase(fetchPipelineSchema.fulfilled, (state, action) => {
      state.fetchPipelineSchemaStatus = StatusCodes.SUCCESS;

      if (action.payload) {
        const schema = action.payload;
        // Remove document_store init parameter from components
        Object.values(schema.definitions.Components).forEach((component: any) => {
          if (
            component?.properties?.init_parameters?.properties?.document_store &&
            !component?.properties?.type?.const?.includes('deepset_cloud_custom_nodes') &&
            !component?.properties?.type?.const?.includes('dc_custom_component')
          ) {
            delete component.properties.init_parameters.properties.document_store;
            // Remove from required array if present
            const required = component.properties.init_parameters.required;
            if (required) {
              const index = required.indexOf('document_store');
              if (index > -1) {
                required.splice(index, 1);
              }
            }
          }
        });

        const documentStoreComponents = Object.entries(schema.definitions.haystackTypes)
          .filter(([key]) => key.includes('DocumentStore'))
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        Object.entries(documentStoreComponents).forEach(([, value]: [string, any]) => {
          if (value?.properties?.init_parameters?.properties?.index) {
            value.properties.init_parameters.required.push('index');
            value.properties.init_parameters.properties.index = {
              ...value.properties.init_parameters.properties.index,
              type: 'string',
              enum: [],
              default: '',
            };
          }
        });

        schema.definitions.Components = {
          ...schema.definitions.Components,
          ...documentStoreComponents,
        };

        const components = schema.definitions?.Components || {};
        state.haystackTypes = schema.definitions?.haystackTypes || {};
        state.pipelineSchema = components;
        state.documentStoreComponents = documentStoreComponents;
        state.componentMap = getComponentMappings(components);
      }
    });
    builder.addCase(fetchPipelineSchema.rejected, (state) => {
      state.fetchPipelineSchemaStatus = StatusCodes.ERROR;
    });

    builder.addCase(fetchPipelineInputOutputs.pending, (state) => {
      state.fetchPipelineInputOutputsStatus = StatusCodes.IN_PROGRESS;
    });
    builder.addCase(fetchPipelineInputOutputs.fulfilled, (state, action) => {
      state.fetchPipelineInputOutputsStatus = StatusCodes.SUCCESS;
      if (action.payload) {
        state.inputOutputMap = getInputOutputMappings(action.payload);
      }
    });
    builder.addCase(fetchPipelineInputOutputs.rejected, (state) => {
      state.fetchPipelineInputOutputsStatus = StatusCodes.ERROR;
    });

    builder.addCase(fetchPipelineYaml.fulfilled, (state, action) => {
      state.indexingYaml = action.payload.indexing_yaml;
      state.queryYaml = action.payload.query_yaml;
    });
    builder.addCase(generateIntegratedPipelineYaml.pending, (state) => {
      state.fetchedSingleIntegratedPipelineYaml = StatusCodes.IN_PROGRESS;
    });
    builder.addCase(generateIntegratedPipelineYaml.fulfilled, (state, action) => {
      // TODO(NEW_STUDIO): Remove when old studio is removed
      if (state.activeTab === StudioYamlTabsKeys.INDEXING_YAML) {
        try {
          yamlParser.load(action.payload);
          state.isIndexingGraphValid = true;
        } catch (e) {
          state.isIndexingGraphValid = false;
        }
        state.indexingYaml = action.payload;
      } else if (state.activeTab === StudioYamlTabsKeys.QUERY_YAML) {
        try {
          yamlParser.load(action.payload);
          state.isQueryGraphValid = true;
        } catch (e) {
          state.isQueryGraphValid = false;
        }
        state.queryYaml = action.payload;
      }

      state.yaml = action.payload;
      state.fetchedSingleIntegratedPipelineYaml = StatusCodes.SUCCESS;
    });
    builder.addCase(generateIntegratedPipelineYaml.rejected, (state, action) => {
      // TODO(NEW_STUDIO): Remove when old studio is removed
      const yamlString = !isEmpty(action.meta.arg.updated)
        ? getYamlString(action.meta.arg.updated)
        : action.meta.arg.original;
      if (state.activeTab === StudioYamlTabsKeys.INDEXING_YAML) state.indexingYaml = yamlString;
      else if (state.activeTab === StudioYamlTabsKeys.QUERY_YAML) state.queryYaml = yamlString;
      state.yaml = yamlString;
      state.fetchedSingleIntegratedPipelineYaml = StatusCodes.ERROR;
    });
    builder.addCase(generateIntegratedIndexingAndQueryPipelineYaml.pending, (state) => {
      state.fetchedIntegratedPipelinesYaml = StatusCodes.IN_PROGRESS;
    });
    builder.addCase(generateIntegratedIndexingAndQueryPipelineYaml.fulfilled, (state, action) => {
      state.indexingYaml = action.payload.indexingYaml;
      state.queryYaml = action.payload.queryYaml;
      state.fetchedIntegratedPipelinesYaml = StatusCodes.SUCCESS;
    });
    builder.addCase(generateIntegratedIndexingAndQueryPipelineYaml.rejected, (state) => {
      state.fetchedIntegratedPipelinesYaml = StatusCodes.ERROR;
    });

    builder.addCase(saveStudioPipeline.pending, (state) => {
      state.saveStudioPipelineStatus = StatusCodes.IN_PROGRESS;
    });
    builder.addCase(saveStudioPipeline.fulfilled, (state, action) => {
      state.saveStudioPipelineStatus = StatusCodes.SUCCESS;
      state.yaml = action.payload;
    });
    builder.addCase(saveStudioPipeline.rejected, (state) => {
      state.saveStudioPipelineStatus = StatusCodes.ERROR;
    });
  },
});

export const {
  resetStudioState,
  setActiveTab,
  setIsYamlView,
  setIndexingYaml,
  setExportCodeModalOpen,
  setYaml,
  setQueryYaml,
  setNodesInputOutput,
  setComponentMap,
  updateInputOutputMap,
  setIndexingNodes,
  setQueryNodes,
  setIndexingEdges,
  setQueryEdges,
  setDownloadYamlModalOpen,
  setIsIndexingGraphValid,
  setIsQueryGraphValid,
  setIsReadOnly,
  setShowReadOnlyWarningModal,
  setHoveredNodeId,
  setReactFlowInstance,
  setPastIndexingReactFlowState,
  setFutureIndexingReactFlowState,
  setPastQueryReactFlowState,
  setFutureQueryReactFlowState,
  setNewNodeAdded,
  setIsDataLoading,
  setNodeParamModalVisible,
  setDisconnectedDocumentStores,
  setIsIndexingPage,
} = studioSlice.actions;

export default studioSlice.reducer;

// SELECTORS
const selectStudioState = (state: RootState) => state.studioStore;

const getPipelineSchema = (state: RootState) => selectStudioState(state).pipelineSchema;
const getHaystackTypes = (state: RootState) => selectStudioState(state).haystackTypes;
const getComponentMap = (state: RootState) => selectStudioState(state).componentMap;
const getFetchPipelineSchemaStatus = (state: RootState) =>
  selectStudioState(state).fetchPipelineSchemaStatus;
const getFetchPipelineInputOutputsStatus = (state: RootState) =>
  selectStudioState(state).fetchPipelineInputOutputsStatus;
const getInputOutputMap = (state: RootState) => selectStudioState(state).inputOutputMap;
const getDocumentStoreComponents = (state: RootState) =>
  selectStudioState(state).documentStoreComponents;
const getActiveTab = (state: RootState) => selectStudioState(state).activeTab;
const getIsYamlView = (state: RootState) => selectStudioState(state).isYamlView;
const getDownloadYamlModalOpen = (state: RootState) =>
  selectStudioState(state).downloadYamlModalOpen;
const getExportCodeModalOpen = (state: RootState) => selectStudioState(state).exportCodeModalOpen;
const getYaml = (state: RootState) => selectStudioState(state).yaml;
const getIndexingYaml = (state: RootState) => selectStudioState(state).indexingYaml;
const getQueryYaml = (state: RootState) => selectStudioState(state).queryYaml;
const getIndexingNodes = (state: RootState) => selectStudioState(state).indexingNodes;
const getQueryNodes = (state: RootState) => selectStudioState(state).queryNodes;
const getIndexingEdges = (state: RootState) => selectStudioState(state).indexingEdges;
const getQueryEdges = (state: RootState) => selectStudioState(state).queryEdges;
const getFetchedSingleIntegratedPipelineYaml = (state: RootState) =>
  selectStudioState(state).fetchedSingleIntegratedPipelineYaml;
const getFetchedIntegratedPipelinesYaml = (state: RootState) =>
  selectStudioState(state).fetchedIntegratedPipelinesYaml;
const getIsIndexingGraphValid = (state: RootState) => selectStudioState(state).isIndexingGraphValid;
const getIsQueryGraphValid = (state: RootState) => selectStudioState(state).isQueryGraphValid;
const getIsReadOnly = (state: RootState) => selectStudioState(state).isReadOnly;
const getShowReadOnlyWarningModal = (state: RootState) =>
  selectStudioState(state).showReadOnlyWarningModal;
const getHoveredNodeId = (state: RootState) => selectStudioState(state).hoveredNodeId;
const getReactFlowInstance = (state: RootState) => selectStudioState(state).reactFlowInstance;
const getPastIndexingReactFlowState = (state: RootState) =>
  selectStudioState(state).pastIndexingReactFlowState;
const getFutureIndexingReactFlowState = (state: RootState) =>
  selectStudioState(state).futureIndexingReactFlowState;
const getPastQueryReactFlowState = (state: RootState) =>
  selectStudioState(state).pastQueryReactFlowState;
const getFutureQueryReactFlowState = (state: RootState) =>
  selectStudioState(state).futureQueryReactFlowState;
const getNewNodeAdded = (state: RootState) => selectStudioState(state).newNodeAdded;
const getIsDataLoading = (state: RootState) => selectStudioState(state).isDataLoading;
const getNodeParamModalVisible = (state: RootState) =>
  selectStudioState(state).nodeParamModalVisible;
const getDisconnectedDocumentStores = (state: RootState) =>
  selectStudioState(state).disconnectedDocumentStores;
const getSaveStudioPipelineStatus = (state: RootState) =>
  selectStudioState(state).saveStudioPipelineStatus;
const getIsIndexingPage = (state: RootState) => selectStudioState(state).isIndexingPage;

// createSelector
export const selectPipelineSchema = createSelector(
  [getPipelineSchema],
  (pipelineSchema) => pipelineSchema,
);
export const selectHaystackTypes = createSelector(
  [getHaystackTypes],
  (haystackTypes) => haystackTypes,
);
export const selectComponentMap = createSelector([getComponentMap], (componentMap) => componentMap);
export const selectInputOutputMap = createSelector(
  [getInputOutputMap],
  (inputOutputMap) => inputOutputMap,
);
export const selectDocumentStoreComponents = createSelector(
  [getDocumentStoreComponents],
  (documentStoreComponents) => documentStoreComponents,
);
export const selectFetchPipelineSchemaStatus = createSelector(
  [getFetchPipelineSchemaStatus],
  (fetchPipelineSchemaStatus) => fetchPipelineSchemaStatus,
);
export const selectFetchPipelineInputOutputsStatus = createSelector(
  [getFetchPipelineInputOutputsStatus],
  (fetchPipelineInputOutputsStatus) => fetchPipelineInputOutputsStatus,
);
export const selectActiveTab = createSelector([getActiveTab], (activeTab) => activeTab);
export const selectIsYamlView = createSelector([getIsYamlView], (isYamlView) => isYamlView);
export const selectDownloadYamlModalOpen = createSelector(
  [getDownloadYamlModalOpen],
  (downloadYamlModalOpen) => downloadYamlModalOpen,
);
export const selectExportCodeModalOpen = createSelector(
  [getExportCodeModalOpen],
  (exportCodeModalOpen) => exportCodeModalOpen,
);
export const selectYaml = createSelector([getYaml], (yaml) => yaml);
export const selectIndexingYaml = createSelector([getIndexingYaml], (indexingYaml) => indexingYaml);
export const selectQueryYaml = createSelector([getQueryYaml], (queryYaml) => queryYaml);
export const selectIndexingNodes = createSelector(
  [getIndexingNodes],
  (indexingNodes) => indexingNodes,
);
export const selectQueryNodes = createSelector([getQueryNodes], (queryNodes) => queryNodes);
export const selectIndexingEdges = createSelector(
  [getIndexingEdges],
  (indexingEdges) => indexingEdges,
);
export const selectQueryEdges = createSelector([getQueryEdges], (queryEdges) => queryEdges);
export const selectFetchedSingleIntegratedPipelineYaml = createSelector(
  [getFetchedSingleIntegratedPipelineYaml],
  (fetchedSingleIntegratedPipelineYaml) => fetchedSingleIntegratedPipelineYaml,
);
export const selectFetchedIntegratedPipelinesYaml = createSelector(
  [getFetchedIntegratedPipelinesYaml],
  (fetchedIntegratedPipelinesYaml) => fetchedIntegratedPipelinesYaml,
);
export const selectIsIndexingGraphValid = createSelector(
  [getIsIndexingGraphValid],
  (isIndexingGraphValid) => isIndexingGraphValid,
);
export const selectIsQueryGraphValid = createSelector(
  [getIsQueryGraphValid],
  (isQueryGraphValid) => isQueryGraphValid,
);
export const selectIsReadOnly = createSelector([getIsReadOnly], (isReadOnly) => isReadOnly);
export const selectShowReadOnlyWarningModal = createSelector(
  [getShowReadOnlyWarningModal],
  (showReadOnlyWarningModal) => showReadOnlyWarningModal,
);
export const selectHoveredNodeId = createSelector(
  [getHoveredNodeId],
  (hoveredNodeId) => hoveredNodeId,
);
export const selectReactFlowInstance = createSelector(
  [getReactFlowInstance],
  (reactFlowInstance) => reactFlowInstance,
);
export const selectPastIndexingReactFlowState = createSelector(
  [getPastIndexingReactFlowState],
  (pastIndexingReactFlowState) => pastIndexingReactFlowState,
);
export const selectFutureIndexingReactFlowState = createSelector(
  [getFutureIndexingReactFlowState],
  (futureIndexingReactFlowState) => futureIndexingReactFlowState,
);
export const selectPastQueryReactFlowState = createSelector(
  [getPastQueryReactFlowState],
  (pastQueryReactFlowState) => pastQueryReactFlowState,
);
export const selectFutureQueryReactFlowState = createSelector(
  [getFutureQueryReactFlowState],
  (futureQueryReactFlowState) => futureQueryReactFlowState,
);
export const selectNewNodeAdded = createSelector([getNewNodeAdded], (newNodeAdded) => newNodeAdded);
export const selectIsDataLoading = createSelector(
  [getIsDataLoading],
  (isDataLoading) => isDataLoading,
);
export const selectNodeParamModalVisible = createSelector(
  [getNodeParamModalVisible],
  (nodeParamModalVisible) => nodeParamModalVisible,
);
export const selectDisconnectedDocumentStores = createSelector(
  [getDisconnectedDocumentStores],
  (disconnectedDocumentStores) => disconnectedDocumentStores,
);
export const selectSaveStudioPipelineStatus = createSelector(
  [getSaveStudioPipelineStatus],
  (saveStudioPipelineStatus) => saveStudioPipelineStatus,
);
export const selectIsIndexingPage = createSelector(
  [getIsIndexingPage],
  (isIndexingPage) => isIndexingPage,
);
